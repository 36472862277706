@import '~handsontable/dist/handsontable.full.css';
/* Styles for mat-icons */
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';

/**
 * App-specific styles
*/

/**
 * Simple Fiehn Lab Theme, from carrot-control-panel
 */

body {
  background-color: #f8f8f8;
  font-family: 'Ubuntu', sans-serif;
}

#page-wrapper {
  padding-top: 60px;
  margin: 20px 50px;
  display: block !important;
}

.nav, .pagination, .carousel, .panel-title a {
  cursor: pointer;
}

form {
  margin-bottom: 2em;
}

a {
  cursor: pointer;
}

/* Handsontable specific styles */

.selectedRow {
/* background-color: #1A3E68 !important; */
/* background-color: #0335ff25 !important; */
  background-color: #c6e7fd !important;
  /* color: white !important; */
}

.selectedRow a {
  /* color: white !important; */
  color: black !important;
  text-decoration: underline !important;
}

/* Angular Material Tool Tip style */
/* .mdc-tooltip__surface added to work for Angular 15 */

.custom-tooltip-binview .mdc-tooltip__surface {
  font-size: 16px !important;
  color: white !important;
  background-color: black !important;
  white-space: pre-line !important;
  padding: 10px !important;
  cursor: pointer !important;
}

.custom-tooltip-width .mdc-tooltip__surface {
  max-width: unset !important;
}

/* cursor: pointer cannot be changed in matTooltipClass, goes in usual class CSS selector */
.cp-tool-tip {
  cursor: pointer;
}

/*  Some basic styling for a material table, currently used by library-duplicates and identifiers-table */

.basic-mat-table-container {
  overflow-x: auto;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.basic-mat-table-container table {
  min-width: 100%;
}

.basic-mat-table-container mat-cell {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.basic-mat-table-container td {
  white-space: nowrap;
}

/* Mat expansion panel styling */

mat-expansion-panel-header {
  background-color: #e8f4fc !important;
  border: #c6e7fd solid 1px;
}

mat-expansion-panel-header:hover {
  background-color: #c6e7fd !important;
}

.expansion-content {
  margin-top: 20px;
  margin-bottom: 20px;
}

option:disabled {
  color: #ddd;
}

.num-selected {
  display: inline-block;
  text-align: center;
  font-weight: bold;
}

mat-dialog-content {
  color: black !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version. */
.mat-input-table-binview {
  display: inline-block !important;
}

.mat-input-table-binview mat-form-field {
  margin-bottom: -1.0em !important;
}

.select-th {
  text-align: center !important;
  width: 50px !important;
}

.select-td {
  text-align: center !important;
  width: 50px !important;
  background-color: #ccc !important;
}

.mat-mdc-table-binview-wrapper {
  border-left: 1px #ccc solid;
  border-right: 1px #ccc solid;
}

/* TODO(mdc-migration): The following rule targets internal classes of paginator that may no longer apply for the MDC version. */
.mat-table-binview-wrapper .mat-paginator-binview {
  border-bottom: 1px #ccc solid;
}

.mat-mdc-table-binview th {
  border-top: 1px #ccc solid !important;
  background-color: #fff !important;
  font-weight: bold !important;
  color: black !important;
  font-size: 100% !important;
}

.mat-mdc-table-binview tr th {
  padding: 1.0rem 1.0rem !important;
}

.mat-table-binview tr td, .mat-table-binview tr th {
  padding: 0.5rem 1.0rem !important;
}

.mat-mdc-filter-fail {
  padding: 15px;
}

/* TODO(mdc-migration): The following rule targets internal classes of paginator that may no longer apply for the MDC version. */
.mat-paginator-binview {
  z-index: 3000;
}

/* ISTD icon colors */

.istd-absent {
  color: #FF8189;
}

.istd-mzrt {
  color: #008EAA;
}

.istd-msms {
  color: #3DAE2B;
}

.atype-explain {
  font-size: 120%;
  text-align: center;
}

/* Material table with expandable rows */

.expand-detail-row {
  height: 0 !important;
}

.expand-element-row:not(.expand-row):hover {
  background: whitesmoke;
}

.expand-element-row:not(.expand-row):active {
  background: #efefef;
}

.expand-element-row td {
  border-bottom-width: 0;
}

.expand-element-detail {
  overflow: hidden;
  display: flex;
}

.expand-element-description {
  /* Removing 2024/05/20, see FIEHNLAB-2940, may return to it later */
  /* padding: 16px; */
  width: 100%;
}

.specktackle-heads-tails {
  border: #ccc solid 1px;
  background-color: white;
  border-radius: 5px;
}

.mat-table-container-scroll {
  overflow-x: auto;
  margin-left: auto;
  margin-right: auto;
}

.binview-placeholder-text {
  font-weight: bold;
  font-style: italic;
  font-size: 110%;
}

/* Highlight differences in values (Correction Report), or for some other use */
.highlight {
  background-color: #6CCA9880;
}

/* Size alerts based on the content within */
.alert-fit-content {
  width: fit-content;
}