/* You can add global styles to this file, and also import other style files */

/* My stuff */
@import url("./assets/styles/main.css");
@import "../node_modules/angular-calendar/css/angular-calendar.css";

:root {
  --amplify-components-tabs-item-active-color: #1A3E68;
  --amplify-components-tabs-item-hover-color: #ffcd00;
  --amplify-components-tabs-item-active-border-color: #1A3E68;
  --amplify-components-authenticator-router-border-width: 0;
  --amplify-components-button-link-color: #1A3E68;
  --amplify-components-button-link-hover-color: #1A3E68;
  --amplify-components-button-link-hover-background-color: #ffcd0055;
  --amplify-components-button-primary-background-color: #1A3E68;
  --amplify-components-button-primary-hover-background-color: #1A3E6888;
  --amplify-components-tabs-border-width: 2px;
}

.login-header {
  font-family: 'Ubuntu', sans-serif;
}
